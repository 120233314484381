<template>
  <div class="details">
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          Contactus Details
        </b-card-title>
      </b-card-header>
      <hr class="my-0">
      <b-card-body no-body>
        <div class="row">
          <div class="col-6 pt-2 pb-1 border-bottom-2">
            <div
                class="row"
            >
              <div class="mb-1 col-sm-3 text-capitalize">
                <span>name:</span>
              </div>
              <div class="col-sm-9">
                {{details?.name}}
              </div>
            </div>
          </div>
          <div class="col-6 pt-2 pb-1 border-bottom-2">
            <div
                class="row"
            >
              <div class="mb-1 col-sm-3 text-capitalize">
                <span>Created At:</span>
              </div>
              <div class="col-sm-9">
                {{details?.created_at}}
              </div>
            </div>
          </div>
          <div class="col-6 pt-2 pb-1 border-bottom-2">
            <div
                class="row"
            >
              <div class="mb-1 col-sm-3 text-capitalize">
                <span>Email:</span>
              </div>
              <div class="col-sm-9">
                {{details?.email}}
              </div>
            </div>
          </div>
          <div class="col-6 pt-2 pb-1 border-bottom-2">
            <div
                class="row"
            >
              <div class="mb-1 col-sm-3 text-capitalize">
                <span>Mobile:</span>
              </div>
              <div class="col-sm-9">
                {{details?.mobile}}
              </div>
            </div>
          </div>
          <div class="col-6 pt-2 pb-1 border-bottom-2">
            <div
                class="row"
            >
              <div class="mb-1 col-sm-3 text-capitalize">
                <span>Status:</span>
              </div>
              <div class="col-sm-9">
                {{details?.status_value}}
              </div>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          Message
        </b-card-title>
      </b-card-header>
      <hr class="my-0">
      <b-card-body no-body>
        <div class="">
          <p class="m-0"> {{details?.message}}</p>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'contactus-details',
  data() {
    return {
      details: null,
    }
  },
  created() {
    this.axios.get(`/contact-us/${this.$route.params.id}`).then(res => {
      this.details = res.data.data
      console.log(res.data.data)
    })
  },
}
</script>

<style>
.border-bottom-2{
  border-bottom: 0.5px solid #3b4253;
}
</style>
