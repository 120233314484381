var render = function () {
  var _vm$details, _vm$details2, _vm$details3, _vm$details4, _vm$details5, _vm$details6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "details"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v(" Contactus Details ")])], 1), _c('hr', {
    staticClass: "my-0"
  }), _c('b-card-body', {
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6 pt-2 pb-1 border-bottom-2"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "mb-1 col-sm-3 text-capitalize"
  }, [_c('span', [_vm._v("name:")])]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$details = _vm.details) === null || _vm$details === void 0 ? void 0 : _vm$details.name) + " ")])])]), _c('div', {
    staticClass: "col-6 pt-2 pb-1 border-bottom-2"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "mb-1 col-sm-3 text-capitalize"
  }, [_c('span', [_vm._v("Created At:")])]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$details2 = _vm.details) === null || _vm$details2 === void 0 ? void 0 : _vm$details2.created_at) + " ")])])]), _c('div', {
    staticClass: "col-6 pt-2 pb-1 border-bottom-2"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "mb-1 col-sm-3 text-capitalize"
  }, [_c('span', [_vm._v("Email:")])]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$details3 = _vm.details) === null || _vm$details3 === void 0 ? void 0 : _vm$details3.email) + " ")])])]), _c('div', {
    staticClass: "col-6 pt-2 pb-1 border-bottom-2"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "mb-1 col-sm-3 text-capitalize"
  }, [_c('span', [_vm._v("Mobile:")])]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$details4 = _vm.details) === null || _vm$details4 === void 0 ? void 0 : _vm$details4.mobile) + " ")])])]), _c('div', {
    staticClass: "col-6 pt-2 pb-1 border-bottom-2"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "mb-1 col-sm-3 text-capitalize"
  }, [_c('span', [_vm._v("Status:")])]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$details5 = _vm.details) === null || _vm$details5 === void 0 ? void 0 : _vm$details5.status_value) + " ")])])])])])], 1), _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v(" Message ")])], 1), _c('hr', {
    staticClass: "my-0"
  }), _c('b-card-body', {
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {}, [_c('p', {
    staticClass: "m-0"
  }, [_vm._v(" " + _vm._s((_vm$details6 = _vm.details) === null || _vm$details6 === void 0 ? void 0 : _vm$details6.message))])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }